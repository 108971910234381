import React, { useState, useMemo } from 'react'

import './skins/kofl/style.css'

const Regular = ({ scale, hiddenTimer, match, convertedTime, statTitle, element }) => {
    return  <div className={'broadcast'+(match && match.config && match.config.skin ? ' _skin-'+match.config.skin : '')} style={{transform: `scale(${scale})`}}>
                <div className={`main${hiddenTimer ? ' noTimer' : ''}`}>
                    <div className='time'>
                        <span>{convertedTime}</span>
                    </div>
                    <div className='team'>
                        {match.colors ? [
                            <span className='color' style={{background: match.colors.homePrimary}}></span>,
                            <span className='color' style={{background: match.colors.homeSecondary}}></span>
                        ] : null}
                        <div className='emb'>
                            <img src={match.homeEmb} />
                        </div>
                        <div className='name'>{match.homeName || '-'}</div>
                    </div>
                    <div className='score'>
                        <span>{match.homeScore || 0}:{match.awayScore || 0}</span>
                    </div>
                    <div className='team'>
                        {match.colors ? [
                            <span className='color' style={{background: match.colors.awayPrimary}}></span>,
                            <span className='color' style={{background: match.colors.awaySecondary}}></span>
                        ] : null}
                        <div className='emb'>
                            <img src={match.awayEmb} />
                        </div>
                        <div className='name'>{match.awayName || '-'}</div>
                    </div>
                    {match && match.config && match.config.skin ? ([
                        <div className='split-score _h'>{match.homeScore || 0}</div>,
                        <div className='split-score _a'>{match.awayScore || 0}</div>
                    ]) : null}
                </div>

                {statTitle ? <div className='stat'>
                    <div className='label'>{statTitle.label}:</div>
                    <div className='value'>{statTitle.home}:{statTitle.away}</div>
                </div> : null}

                {match.config && match.config.staticFouls ? <div className='stat static'>
                    <div className='label'>Фолы:</div>
                    <div className='value'>{match.homeFouls}:{match.awayFouls}</div>
                </div> : null}

                {element ? <div className='event'>
                    <div className='icon'>
                        <img src={require(`./${element.type === 'yellowcard' ? 'yellow' : element.type === 'redcard' ? 'red' : 'goal'}.png`).default} />
                    </div>
                    <div className='photo'>
                        <img src={element.photo} />
                    </div>
                    <div className='content'>
                        <div>{element.type === 'yellowcard' ? 'предупреждение' : element.type === 'redcard' ? 'удаление' : 'ГОЛ!'}</div>
                        <div>{element.name}</div>
                        <div>{element.club}</div>
                    </div>
                </div> : null}

                <div className='promo'>
                </div>
            </div>
}

export default Regular
