import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import './style.css'
import Regular from './Regular'
import Arena from './Arena'
const { io } = require('socket.io-client')
const SOCK_ENDPOINT = process.env.NODE_ENV === 'production' ? 'https://streamsocket.amateum.com/' : 'http://localhost:9009/'
//const SOCK_ENDPOINT = 'https://streamsocket.amateum.com/'

const Widget = ({ width, isCapturer }) => {
    const [socket, setSocket] = useState(null)
    const [element, setElement] = useState(null)
    const [statTitle, setStatTitle] = useState(null)
    const [match, setMatch] = useState({})
    const [seconds, setSeconds] = useState(0)

    const ticker = useRef(false)
    const matchRef = useState({})
    const secondsRef = useRef(0)

    const { streamerId } = useParams()
    const scale = width < 1280 ? width/1280 : 1

    useEffect(() => {
        let tickerWorker = new Worker('/workers/ticker.js')
        const connect = async () => {
            const instance = io(`${SOCK_ENDPOINT}${streamerId}`)
            console.log(`${SOCK_ENDPOINT}${streamerId}`)
            tickerWorker.onmessage = async msg => {
                if(msg.data && msg.data.action === 'tick') {
                    if(ticker.current) {
                        secondsRef.current = secondsRef.current+1
                        setSeconds(secondsRef.current+1)
                    }
                }
            }

            setSocket(instance)
        }

        connect()

        return () => {
            tickerWorker = null
        }
    }, [streamerId])

    useEffect(() => {
        if(socket) {
            console.log('Raised socket client. Adding listeners...')
            socket.on('joinMatch', data => {
                const matchChanged = (matchRef.current && matchRef.current._id && matchRef.current._id !== data._id)
                matchRef.current = data
                setMatch(data)
                if(matchChanged) {
                    secondsRef.current = 0
                    setSeconds(0)
                } else {
                    if(data.syncSeconds) {
                        secondsRef.current = data.syncSeconds
                        setSeconds(data.syncSeconds)
                    }
                }

                ticker.current = (data.timer === true)
            })

            socket.on('setColors', data => {
                matchRef.current = {
                    ...matchRef.current,
                    colors: data
                }

                setMatch(matchRef.current)
            })

            socket.on('forceReload', () => {
                setTimeout(() => {
                    if(window && window.location && typeof(window.location.reload) !== 'undefined') {
                        window.location.reload()
                    }
                }, 2000)
            })

            socket.on('resetMatchTimer', () => {
                ticker.current = false
                secondsRef.current = false
                setSeconds(0)
                setMatch({
                    ...matchRef.current,
                    syncSeconds: 0,
                    timer: false
                })
            })

            socket.on('signal', data => {
                switch (data.type) {
                    case 'arenaResult':
                        const curRefData = matchRef.current.arenaData
                        setElement({
                            ...data,
                            teams: data.outcome === 'win' ? curRefData.teams[parseInt(data.subject)].name : curRefData.current.map(idx => curRefData.teams[parseInt(idx)].displayName).join(', ')
                        })
                        break
                    case 'updateArena':
                        const aupd = {
                            ...matchRef.current,
                            arenaData: data.value
                        }

                        matchRef.current = aupd
                        setMatch(aupd)
                        break
                    case 'update':
                        const upd = {
                            ...matchRef.current,
                            [data.key]: data.value
                        }

                        matchRef.current = upd
                        setMatch(upd)
                        break
                    case 'toggleTimer':
                        ticker.current = data.state
                        break
                    case 'goal':
                    case 'yellowcard':
                    case 'redcard':
                        setElement(data)
                        break
                    case 'showStat':
                        setStatTitle(data)
                        break
                }
            })
        }
    }, [socket])

    useEffect(() => {
        if(element) {
            setTimeout(() => {
                console.log('Cleaning up person element')
                setElement(null)
            }, 12000)
        }
    }, [element])

    useEffect(() => {
        if(statTitle) {
            setTimeout(() => {
                console.log('Cleaning up stat title')
                setElement(null)
            }, 12000)
        }
    }, [statTitle])

    useEffect(() => {
        if(seconds%5 === 0 && socket) {
            socket.emit('syncSeconds', [match._id, seconds])
        }
    }, [seconds])

    const convertTime = () => {
        if(!isNaN(parseInt(seconds))) {
            const mins = Math.floor(parseInt(seconds)/60)
            const sec = (seconds+60)%60
            return `${mins < 10 ? '0' : ''}${mins}:${sec < 10 ? '0' : ''}${sec}`
        } else {
            return '00:00'
        }
    }

    const hiddenTimer = (match.config && match.config.showTimer === false)

    return  match.isArena ? (
        <Arena
            scale={scale}
            data={match.arenaData}
            convertedTime={convertTime()}
            element={element}
        />
    ) : (
        <Regular
            scale={scale}
            hiddenTimer={hiddenTimer}
            match={match}
            convertedTime={convertTime()}
            statTitle={statTitle}
            element={element}
        />
    )
}

export default Widget
