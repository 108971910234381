import React, { useState, forwardRef } from 'react'

import './arena.css'

const ArenaItem = forwardRef(({ item }, ref) => (
    <div className={'arena-item'+(item.isActive ? ' active' : '')} ref={ref}>
        <div className='team'><span>{item.name}</span></div>
        <div className='value'>{item.stats.pts}</div>
        <div className='value'>{item.stats.fs}</div>
        <div className='colors'>
            <div style={{background: item.primaryColor}}></div>
            <div style={{background: item.secondaryColor}}></div>
        </div>
    </div>
))

const Arena = ({ scale, data, convertedTime, element }) => {
    const merged = data.teams.reduce((acc, team, i) => {
        acc.push({
            ...team,
            stats: data.stats ? data.stats[i] || {pts: 0, fs: 0} : {pts: 0, fs: 0},
            isActive: data.current ? data.current.includes(i.toString()) : false
        })
        return acc
    }, [])

    const sorted = merged.sort((a, b) => {
        return a.stats.pts > b.stats.pts ? -1 : b.stats.pts > a.stats.pts ? 1 : a.stats.fs > b.stats.fs ? 1 : b.stats.fs > a.stats.fs ? -1 : 0
    })

    return  <div className='broadcast arena-broadcast' style={{transform: `scale(${scale})`}}>
                <div className='arena-main'>
                    <div className='arena-main_content'>
                        <div className='arena-item head'>
                            <div className='time'>
                                <span>{convertedTime}</span>
                            </div>
                            <div className='label'>очки</div>
                            <div className='label'>фолы</div>
                        </div>
                        {sorted.map((item, idx) => (
                            <ArenaItem
                                key={`item_${idx}`}
                                item={item}
                            />
                        ))}
                    </div>
                </div>

                {element ? (
                    <div className='arena-element'>
                        <div className='emblem'>
                            <div className='emblem-inner'>
                                <img src={require(element.outcome === 'win' ? './trophy.gif' : './handshake.gif').default} />
                            </div>
                        </div>
                        <div className='info'>
                            <div>{element.outcome === 'win' ? '+3 очка' : '+1 очко'}</div>
                            <div>{element.teams}</div>
                        </div>
                    </div>
                ) : null}
            </div>
}

export default Arena
