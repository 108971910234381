import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { withResizeDetector } from 'react-resize-detector'
import Widget from './components/Widget'

function App({ width, height }) {
    const query = new URLSearchParams(window.location.search)
    const isCapturer = query.get('capturer') === 'true'

  return (
    <div className={`app${isCapturer ? ' capturer' : ''}`}>
        <Router>
            <Switch>
                <Route path='/:streamerId'>
                    <Widget width={width} isCapturer={isCapturer} />
                </Route>
                <Route path='/'>
                    <div>Invalid opreator ID</div>
                </Route>
            </Switch>
        </Router>
    </div>
  );
}

export default withResizeDetector(App);
